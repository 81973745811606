<template>
  <b-row class="match-height">

    <b-col lg="12" md="12"> 
      <group-create-transport-options :can-edit="canEdit" :group-id="currentGroupId" />
    </b-col>


  </b-row>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BCard, BCardBody, BCardText, BButton,BButtonGroup,BCollapse,VBToggle } from 'bootstrap-vue'

import GroupCreateTransportOptions from "@/modules/group/creation-wizard/groupCreateTransportOptions/GroupCreateTransportOptions";



export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BButton,
    BCollapse,
    BButtonGroup,
    GroupCreateTransportOptions
  },
  props: [
    'transportOptions', 
    'currentGroupId', 
    'canEdit'],
  data() {
    return {}
  },
  directives: {
    'b-toggle': VBToggle,
  },
  methods: {

  },
  computed: {
    hasOneOption() {
      return this.transportOptions.length > 0
    }
  }
}
</script>

<style>



</style>
